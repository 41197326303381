import React from 'react'
import LoadingSpinner from '../../../components/LoadingDots'
import { Div } from 'atomize-jnh'

const FetchDataSpinner = () => {
  return (
    <Div pos="relative" h="200px">
      <Div p={{ l: '47%', t: '80px' }}>
        <LoadingSpinner />
      </Div>
    </Div>
  )
}

export default FetchDataSpinner

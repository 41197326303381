import React from 'react'
import PropTypes from 'prop-types'
import { Row, Div, Text } from 'atomize-jnh'

function WideIconCard (props) {
  const { icon, title, subtitle } = props
  return (
    <Div
      align="center"
      shadow="front"
      rounded="md"
      h={{ xs: '240px', md: '250px', lg: '240px' }}
      bg="white"
      p="1rem">
      <Row>
        <Div
          align="center"
          m="0 auto"
          rounded="circle"
          h="90px"
          w="90px"
          bgImg={icon}
          bgSize="cover"
        />
        <Div p={{ l: '0.5rem', t: '1rem', b: '1.5rem', r: '0.5rem' }}>
          <Text
            tag="h3"
            textAlign={{ xs: 'justify', md: 'center' }}
            textSize={{ xs: 'paragraph', md: 'subheader' }}
            textColor="#666586">
            {title}
          </Text>
          <Text
            tag="p"
            textAlign={{ xs: 'justify', md: 'center' }}
            textSize={{ xs: 'body', md: 'caption' }}
            textColor="#666586">
            {subtitle}
          </Text>
        </Div>
      </Row>
    </Div>
  )
}

WideIconCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default WideIconCard

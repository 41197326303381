import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import { Text } from 'atomize-jnh'
import './styles.css'

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `scale(${s})`

function Card (props) {
  const { title, variant, id, clickFx, selectedCard } = props

  const [propas, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 }
  }))

  useEffect(() => {
    if (selectedCard === id) set({ xys: [0, 0, 1.03] })
    else {
      set({ xys: [0, 0, 1] })
    }
  })

  return (
    <animated.div
      className="card"
      onClick={({ clientX: x, clientY: y }) => {
        clickFx()
        set({ xys: calc(x, y) })
      }}
      style={{
        transform: propas.xys.interpolate(trans),
        background: variant === 'secondary' ? '#47baf8' : '#1478f6'
      }}>
      <Text
        tag="h3"
        textSize="20px"
        style={{ lineHeight: '30px' }}
        textAlign="center"
        textColor="#fff">
        {title}
      </Text>
    </animated.div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  opt: PropTypes.string,
  id: PropTypes.string,
  clickFx: PropTypes.func,
  selectedCard: PropTypes.string
}

export default Card

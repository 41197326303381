import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text } from 'atomize-jnh'

const EngageMessage = ({ title, punchline, punchline2 }) => {
  return (
    <Div p={{ t: { md: '3rem', lg: '2.5rem' } }}>
      <Text
        tag="h1"
        textAlign={{ xs: 'center', md: 'left' }}
        textTransform="uppercase"
        p={{ l: { xs: '0.5rem', md: '0' }, r: { xs: '0.5rem' } }}
        m={{ b: { xs: '24px', md: '.5rem' } }}
        textSize={{ xs: 'heading', md: 'title', lg: 'display1' }}
        textColor="#fff">
        {title}
      </Text>
      <Text
        tag="h2"
        p={{ l: { xs: '.4rem', md: '0' }, r: { xs: '.4rem', md: '0' } }}
        textAlign={{ xs: 'center', md: 'justify' }}
        textWeight="400"
        style={{ lineHeight: '130%', fontStyle: 'italic' }}
        textSize={{ xs: 'body', md: 'body', lg: 'subheader' }}
        textColor="#fff"
        w={{ md: '80%' }}>
        {punchline}
      </Text>

      <Text
        tag="p"
        m={{ b: { xs: '24px', md: '1rem' }, t: { xs: '12px', md: '12px' } }}
        p={{ l: { xs: '.4rem', md: '0' }, r: { xs: '.4rem', md: '0' } }}
        textAlign={{ xs: 'center', md: 'justify' }}
        textWeight="900"
        style={{ lineHeight: '130%' }}
        textSize={{ xs: 'body', md: 'body', lg: 'subheader' }}
        textColor="#fff">
        {punchline2}
      </Text>
    </Div>
  )
}

EngageMessage.propTypes = {
  title: PropTypes.string,
  punchline: PropTypes.string,
  punchline2: PropTypes.string
}

export default EngageMessage

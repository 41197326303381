import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text, Input, Icon } from 'atomize-jnh'
import { useIntl } from 'gatsby-plugin-intl'

TableTitle.propTypes = {
  tableType: PropTypes.string,
  onChange: PropTypes.func,
  searchValue: PropTypes.string
}

function TableTitle (props) {
  const intl = useIntl()
  const { tableType, onChange, searchValue } = props
  function renderSearch () {
    if (tableType === 'landing') return
    return (
      <Div h="50px" d="flex" w="100%" align="flex-end" justify="flex-end">
        <Div h="50px" w={{ xs: '130px', md: '180px' }}>
          <Input
            placeholder={intl.formatMessage({ id: 'priceTable.search' })}
            p={{ x: '2.5rem' }}
            value={searchValue}
            onChange={onChange}
            prefix={
              <Icon
                name="Search"
                color="rgb(35, 186, 255)"
                size="16px"
                cursor="pointer"
                pos="absolute"
                top="50%"
                left="0.75rem"
                transform="translateY(-50%)"
              />
            }
          />
        </Div>
      </Div>
    )
  }

  return (
    <Div d="flex" p={{ l: '1.5rem', t: '1.5rem', r: '1.5rem' }}>
      <Div>
        <Text textSize="title" textWeight="800">
          {intl.formatMessage({ id: 'priceTable.tableTitle' })}
        </Text>
        <br />
      </Div>
      {renderSearch()}
    </Div>
  )
}

export default TableTitle

import React from 'react'
import PropTypes from 'prop-types'
import Carousel from './MediaIndex'
// CREDITS for Carousel:
// https://medium.com/@incubation.ff/build-your-own-css-carousel-in-react-part-one-86f71f6670ca

function App (props) {
  const { children, type } = props
  return (
    <Carousel type={type} title="Carousel">
      {children}
    </Carousel>
  )
}

App.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string
}

export default App

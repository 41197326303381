import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Div, Input } from 'atomize-jnh'
import Button from '../../../Button'

const EmailForm = ({ inputLabel, buttonText }) => {
  let form = ''

  return (
    <Div>
      <Div m={{ t: { xs: '48px', md: '24px' } }} w={{ sm: '80%', md: '70%' }}>
        <label htmlFor="email">
          <Input
            onChange={event => {
              form = event.target.value
            }}
            type="email"
            name="email"
            rounded="circle"
            placeholder={inputLabel}
          />
        </label>
      </Div>

      <Div m={{ t: { xs: '28px', md: '20px' } }}>
        <Button
          onClick={() => navigate(`/funnel?id=register&email=${form}`)}
          content={buttonText}
          variant="light"
        />
      </Div>
    </Div>
  )
}

EmailForm.propTypes = {
  inputLabel: PropTypes.string,
  buttonText: PropTypes.string
}

export default EmailForm

export default function (oldObject, newPairData, toRebuildPair) {
  const newObject = []
  for (const pairName in oldObject) {
    if (pairName === toRebuildPair) {
      newObject[pairName] = newPairData
      continue
    }
    newObject[pairName] = oldObject[pairName]
  }
  return newObject
}

import React, { useState } from 'react'
import { Row, Col, Div, Text, Container, ThemeProvider, scrollTo } from 'atomize-jnh'
import Button from '../../Button'
import SimpleCard from '../../Cards/SimpleCard'
import SpringCard from '../../Cards/SimpleCard/SpringCard'
import CarouselExample from '../../../containers/Carousel/example'
import { useIntl } from 'gatsby-plugin-intl'
import mainImage from '../../../assets/ilustracion-landing-02.svg'
import mainImage2 from '../../../assets/ilustracion-landing-01.svg'
const theme = {
  grid: {
    gutterBottom: '64px'
  }
}

const DynamicQuadCards = props => {
  const [selectedCard, handleClickCard] = useState('0')
  const intl = useIntl()
  const displayInfo = [
    {
      title: intl.formatMessage({ id: 'dynamicQuadCards.new.title' }),
      content: intl.formatMessage({ id: 'dynamicQuadCards.new.content' }),
      buttonMsg: intl.formatMessage({ id: 'dynamicQuadCards.new.buttonMsg' }),
      imageSvg: mainImage,
      link: 'https://support.orionx.com/es/'
    },
    {
      title: intl.formatMessage({ id: 'dynamicQuadCards.invest.title' }),
      content: intl.formatMessage({ id: 'dynamicQuadCards.invest.content' }),
      buttonMsg: intl.formatMessage({ id: 'dynamicQuadCards.invest.buttonMsg' }),
      imageSvg: mainImage2,
      link: 'https://support.orionx.com/es/category/criptomonedas-t8krwb/'
    },
    {
      title: intl.formatMessage({ id: 'dynamicQuadCards.learn.title' }),
      content: intl.formatMessage({ id: 'dynamicQuadCards.learn.content' }),
      buttonMsg: intl.formatMessage({ id: 'dynamicQuadCards.learn.buttonMsg' }),
      imageSvg: mainImage,
      link: 'https://support.orionx.com/es/category/plataforma-p6nzrr/'
    },
    {
      title: intl.formatMessage({ id: 'dynamicQuadCards.company.title' }),
      content: intl.formatMessage({ id: 'dynamicQuadCards.company.content' }),
      buttonMsg: intl.formatMessage({ id: 'dynamicQuadCards.company.buttonMsg' }),
      imageSvg: mainImage2,
      link: 'https://support.orionx.com/es/article/verificacion-de-empresas-chilenas-cvgei5/'
    }
  ]

  return (
    <Div>
      <Div m={{ t: '6rem' }}>
        <Div d={{ xs: 'none', md: 'flex' }} m={{ b: '-3rem' }}>
          <ThemeProvider theme={theme}>
            <Container>
              <Row d="flex" w={{ md: '100%', xl: '100%' }} m="0 auto" justify="center">
                {displayInfo.map((data, index) => {
                  return (
                    <Col key={index} size={{ md: '3', xs: '8' }}>
                      <SpringCard
                        title={data.title}
                        variant={index % 2 ? 'secondary' : 'primary'}
                        opt="first"
                        id={index.toString()}
                        clickFx={() => {
                          scrollTo('#content')
                          handleClickCard(index.toString())
                        }}
                        selectedCard={selectedCard}
                      />
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </ThemeProvider>
        </Div>
        <Div d={{ md: 'none' }} m={{ b: '-2rem' }} p={{ l: '1rem' }}>
          <CarouselExample
            clickFx={index => {
              scrollTo('#content')
              let newIndex = index
              if (index === 0) newIndex = 1
              if (index === 1) newIndex = 2
              if (index === 2) newIndex = 3
              if (index === 3) newIndex = 0
              handleClickCard(newIndex)
            }}>
            <SimpleCard title={displayInfo[3].title} variant="secondary" />
            <SimpleCard title={displayInfo[0].title} variant="primary" />
            <SimpleCard title={displayInfo[1].title} variant="secondary" />
            <SimpleCard title={displayInfo[2].title} variant="primary" />
          </CarouselExample>
        </Div>
      </Div>
      <Div
        bg="#e9f3fe"
        id="content"
        p={{
          t: { xs: '2.5rem', md: '5rem', xl: '10rem' },
          b: { xs: 0, md: '3rem', xl: '10rem' }
        }}>
        <Container>
          <Row justify="center" p={{ t: '2rem' }}>
            <Col
              order={{ xs: 2, md: 1 }}
              w="100%"
              d="flex"
              justify="center"
              size={{ xs: '12', md: '5', lg: '6' }}>
              <Div
                align="center"
                w="100%"
                h={{ xs: '19rem', lg: '24rem' }}
                bgImg={displayInfo[selectedCard].imageSvg}
                bgSize="contain"
                bgRepeat="no-repeat"
              />
            </Col>
            <Col order={{ xs: 1, md: 1 }} size={{ xs: '12', md: '6', lg: '6' }}>
              <Div p={{ t: { md: '1rem', lg: '6.5rem' }, b: { xs: '3rem', md: '0' }, l: { md: '3rem' } }}>
                <Text
                  tag="h2"
                  m={{ b: '1.1rem' }}
                  textSize="30px"
                  textAlign={{ xs: 'center', md: 'left' }}
                  textColor="#37465a">
                  {displayInfo[selectedCard].title}
                </Text>
                <Text
                  tag="p"
                  m={{ b: { xs: '1.7rem', md: '1.8rem', lg: '1.4rem' } }}
                  textAlign={{ xs: 'justify', md: 'justify' }}
                  style={{ lineHeight: '130%' }}
                  textColor="#6b7c93">
                  {displayInfo[selectedCard].content}
                </Text>

                <Button
                  onClick={() => {
                    window.open(displayInfo[selectedCard].link)
                  }}
                  content={displayInfo[selectedCard].buttonMsg}
                  variant="primary"
                />
              </Div>
            </Col>
          </Row>
        </Container>
      </Div>
    </Div>
  )
}

export default DynamicQuadCards

import { currenciesNames } from '../data/currencies'

export default function (ticker, option) {
  const secondaries = {}

  for (const pair in ticker) {
    const pairData = ticker[pair]
    secondaries[pairData.priceCurrencyCode] = true
  }
  delete secondaries.MXN
  if (option === 'tab-selector') {
    const formattedSecondaries = []
    for (const secondaryCurrency in secondaries) {
      formattedSecondaries.push({
        value: secondaryCurrency,
        label: currenciesNames[secondaryCurrency].currencyName
      })
    }
    return formattedSecondaries
  }
  return secondaries
}

import React from 'react'
import PropTypes from 'prop-types'
import { Div, Container, Row, Col, Text, Button } from 'atomize-jnh'
import { navigate } from 'gatsby'
import LoadingSpinner from '../../../components/LoadingDots'
import getChangeColor from '../../../helpers/getChangeColor'
import numberWithCommas from '../../../helpers/numberWithCommas'
import percentFormatter from '../../../helpers/percentFormatter'
import CoinCircle from '../../CoinCircle'
import { currenciesNames } from '../../../data/currencies'

TableRow.propTypes = {
  currencyCode: PropTypes.string,
  currencyName: PropTypes.string,
  customRoute: PropTypes.string,
  lastPrice: PropTypes.number,
  variant: PropTypes.number,
  globalPrice: PropTypes.number,
  clpPrice: PropTypes.number,
  actionButton: PropTypes.string,
  selectedCurrency: PropTypes.string,
  tableType: PropTypes.string,
  langCode: PropTypes.string
}

function TableRow (props) {
  const {
    currencyCode,
    currencyName,
    customRoute,
    lastPrice,
    variant,
    globalPrice,
    clpPrice,
    actionButton,
    selectedCurrency,
    tableType,
    langCode
  } = props

  function renderGlobalPrice () {
    if (selectedCurrency !== 'CLP' || tableType === 'landing') return false
    return (
      <Col d={{ xs: 'none', md: 'inline-block' }} size="2">
        <Text
          tag="p"
          textAlign="center"
          p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
          textColor="#37465a"
          w={{ xs: '125%', md: '100%' }}
          textSize={{ xs: 'caption', md: 'body' }}>
          {globalPrice !== undefined ? `$${numberWithCommas(globalPrice)}` : <LoadingSpinner />}
        </Text>
      </Col>
    )
  }

  function renderNoDecimalPrice (decimals) {
    if (decimals === 0) return numberWithCommas(lastPrice)
    return lastPrice.toFixed(currenciesNames[selectedCurrency].decimals)
  }

  return (
    <Div border={{ b: '1px solid' }} borderColor="#eceff1">
      <Container>
        <Row p={{ t: '10px', b: '10px' }}>
          <Col size={{ xs: '6', md: '3' }} pos="relative" d="flex">
            <Div p={{ t: { xs: '3px', md: 0 } }}>
              <CoinCircle size={30} currencyCode={currencyCode} />
            </Div>

            <Div pos="absolute" d={{ md: 'flex' }} left={{ xs: '36px', md: '30px' }}>
              <Text
                visibility="hidden"
                textSize={{ xs: 'caption', md: 'body' }}
                textColor="#37465a"
                p={{ l: { xs: '9px', md: '12px' }, t: { md: '4px' } }}>
                {currencyName}
              </Text>
              <Text
                visibility="hidden"
                textSize="caption"
                h={{ xs: 0 }}
                textColor="#c4ceda"
                p={{ l: '9px', t: { md: '7px' } }}>
                {currencyCode}
              </Text>
            </Div>
          </Col>
          {renderGlobalPrice(selectedCurrency, tableType)}
          <Col size={{ xs: '3', md: '3' }}>
            <Text
              tag="p"
              d={{ xs: 'flex', md: 'none' }}
              t={{ t: { xs: '-5px' } }}
              textColor="#37465a"
              w={{ xs: '125%', md: '100%' }}
              textSize={{ xs: 'caption', md: 'body' }}>
              {`${currenciesNames[selectedCurrency].unicode}
              ${renderNoDecimalPrice(currenciesNames[selectedCurrency].decimals)}`}
            </Text>
            <Text
              tag="p"
              textAlign="center"
              d={{ xs: 'none', md: 'block' }}
              p={{ md: selectedCurrency === 'BTC' ? '0rem' : '0.1rem' }}
              textColor="#37465a"
              w={{ xs: '125%', md: '100%' }}
              textSize={{ xs: 'caption', md: 'body' }}>
              {`${currenciesNames[selectedCurrency].unicode}          
                ${renderNoDecimalPrice(currenciesNames[selectedCurrency].decimals)}`}
            </Text>
            <Text
              d={{ md: 'none' }}
              w={{ xs: '70px', md: '100%' }}
              textAlign={{ md: 'center' }}
              textSize="tiny"
              textColor={getChangeColor(variant)}>
              {clpPrice !== undefined ? `${percentFormatter(variant * 100)}%` : ''}
            </Text>
            {selectedCurrency === 'BTC' ? (
              <Text
                d={{ xs: 'none', md: 'block' }}
                w={{ xs: '70px', md: '100%' }}
                textAlign={{ md: 'center' }}
                textSize="tiny"
                h="0">
                {clpPrice !== undefined ? `CLP: ~$${numberWithCommas(clpPrice.toFixed(0))}` : ''}
              </Text>
            ) : (
              <div />
            )}
          </Col>
          <Col
            d={{ xs: 'none', md: 'block' }}
            size={{
              xs: '2',
              md: selectedCurrency === 'CLP' ? (tableType === 'landing' ? '3' : '2') : '3'
            }}>
            <Text
              tag="p"
              p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
              textAlign="center"
              textWeight="500"
              textColor={getChangeColor(variant)}
              w={{ xs: '155%', md: '100%' }}
              textSize={{ xs: 'caption', md: 'body' }}>
              {`${percentFormatter(variant * 100)}%`}
            </Text>
          </Col>
          <Col size={{ xs: '2', md: selectedCurrency === 'CLP' ? '2' : '3' }}>
            <Div>
              <Button
                h="35px"
                textWeight="900"
                textSize="caption"
                textColor="#23baff"
                hoverTextColor="#1a8fc4"
                bg="#e8f8ff"
                w={{ xs: '74px', md: '150px' }}
                hoverBg="#cff0ff"
                rounded="circle"
                hoverShadow="4"
                onClick={() => {
                  if (!customRoute) {
                    navigate(`${langCode}/market-simple/?id=${currencyCode}`)
                  } else {
                    navigate(customRoute)
                  }
                }
                }
                m={{ l: { md: '0.5rem', xs: 'auto' }, r: 'auto' }}>
                <Div d={{ md: 'none' }}>{actionButton.mobile}</Div>
                <Div d={{ xs: 'none', md: 'block' }}>{actionButton.desktop}</Div>
              </Button>
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

export default TableRow

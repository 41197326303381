import React from 'react'
import { Div } from 'atomize-jnh'
import mainImage from '../../../../assets/ilustracion-landing-01.svg'
const Illustration = () => {
  return (
    <Div
      align="center"
      w="100%"
      h={{ xs: '22rem', md: '17rem', lg: '24rem' }}
      bgImg={mainImage}
      m={{ t: { xs: '2rem', sm: '0', md: '0', lg: 0 } }}
      bgSize="contain"
      bgRepeat="no-repeat"
    />
  )
}

export default Illustration

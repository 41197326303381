import React from 'react'
import { Container, Row, Col, Div } from 'atomize-jnh'
import Button from '../../Button'
import ResponsiveTable from '../../Table/prices'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

function renderTable (props) {
  const intl = useIntl()
  const data = {
    langCode: intl.formatMessage({ id: 'langCode' }),
    tableData: {
      tableTitle: intl.formatMessage({ id: 'priceTable.tableTitle' }),
      actionButton: {
        desktop: intl.formatMessage({ id: 'priceTable.actionButton.desktop' }),
        mobile: intl.formatMessage({ id: 'priceTable.actionButton.mobile' })
      },
      header: {
        currency: intl.formatMessage({ id: 'priceTable.header.currency' }),
        price: intl.formatMessage({ id: 'priceTable.header.price' }),
        change: intl.formatMessage({ id: 'priceTable.header.change' }),
        operate: intl.formatMessage({ id: 'priceTable.header.operate' }),
        global: intl.formatMessage({ id: 'priceTable.header.global' })
      }
    }
  }
  return (
    <div style={{ width: '100vw', marginTop: '-2rem' }}>
      <Container>
        <Row justify="center" d="flex">
          <Col pos="relative" size={{ md: '10', lg: '8', xs: '12' }}>
            <ResponsiveTable
              langCode={data.langCode}
              tableType="landing"
              tableData={data.tableData}
            />
            <Div m={{ t: '24px' }}>
              <Div w="168px" m="0 auto">
                <Button
                  onClick={() => navigate('/criptomonedas-chile')}
                  variant="primary"
                  content={data.langCode === 'es' ? 'Ver Mercados' : 'View Markets'}
                />
              </Div>
            </Div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default renderTable

import React from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { Wrapper, CarouselContainer, CarouselSlot, PREV, NEXT } from './components'

const getOrder = ({ index, pos, numItems }) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
}
const initialState = { pos: 0, sliding: false, dir: NEXT }

const Carousel = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const numItems = React.Children.count(props.children)
  const { clickFx } = props
  const slide = dir => {
    dispatch({ type: dir, numItems })
    setTimeout(() => {
      dispatch({ type: 'stopSliding' })
    }, 50)
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      clickFx(state.pos)
      slide(NEXT)
    },
    onSwipedRight: () => {
      slide(PREV)
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return (
    <div {...handlers}>
      <Wrapper>
        <CarouselContainer dir={state.dir} sliding={state.sliding}>
          {React.Children.map(props.children, (child, index) => (
            <CarouselSlot key={index} order={getOrder({ index: index, pos: state.pos, numItems })}>
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
    </div>
  )
}

function reducer (state, { type, numItems }) {
  switch (type) {
    case 'reset':
      return initialState
    case PREV:
      return {
        ...state
      }
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === numItems - 1 ? 0 : state.pos + 1
      }
    case 'stopSliding':
      return { ...state, sliding: false }
    default:
      return state
  }
}

Carousel.propTypes = {
  children: PropTypes.any,
  clickFx: PropTypes.func
}

export default Carousel

import getSecondaryCurrencies from './getSecondaryCurrencies'
import { currenciesNames } from '../data/currencies'

export default function getPairsBySecondaryCurrencies (ticker) {
  const secondaries = getSecondaryCurrencies(ticker)
  const pairsBySecondaryCurrency = {}

  for (const currency in secondaries) {
    pairsBySecondaryCurrency[currency] = []
  }

  for (const pair in ticker) {
    const pairData = ticker[pair]
    if (pairData.priceCurrencyCode === 'MXN') continue
    pairsBySecondaryCurrency[pairData.priceCurrencyCode].push({
      ...pairData,
      currencyName: currenciesNames[pairData.amountCurrencyCode].currencyName,
      clpPrice: pairData.lastPrice * ticker.BTCCLP.lastPrice
    })
  }

  return pairsBySecondaryCurrency
}

import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'simple-react-form'
import CurrencySelector from '../../fields/SegmentedControl/darkSegmentedControl'
import { Div } from 'atomize-jnh'

CurrenciesTabSelector.propTypes = {
  options: PropTypes.array,
  selectedCurrency: PropTypes.string,
  setCurrency: PropTypes.func
}

export default function CurrenciesTabSelector ({ options, selectedCurrency, setCurrency }) {
  return (
    <Div m={{ t: '24px' }} w={{ md: '40%', xs: '100%' }}>
      <div style={{ margin: '0 auto' }} className="col-xs-12 col-md-8">
        <Form
          state={{ type: selectedCurrency }}
          onChange={({ type }) => {
            setCurrency(type)
          }}>
          <Field
            fieldName="type"
            options={options}
            selectedCurrency={selectedCurrency}
            type={CurrencySelector}
          />
        </Form>
      </div>
    </Div>
  )
}

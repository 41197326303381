import React from 'react'
import PropTypes from 'prop-types'
import { Div, Container, Text } from 'atomize-jnh'
import { useIntl } from 'gatsby-plugin-intl'

export default function GlobalPriceDisclaimer (props) {
  const intl = useIntl()
  GlobalPriceDisclaimer.propTypes = {
    globalPrice: PropTypes.string
  }

  const { globalPrice } = props

  if (globalPrice) {
    return (
      <Div p={{ t: '12px', b: '12px' }} d={{ xs: 'none', md: 'flex' }}>
        <Container>
          <Text textWeight="500" textColor="#c4ceda" textSize="body">
            * {intl.formatMessage({ id: 'priceTable.globalDisclaimer' })}
          </Text>
        </Container>
      </Div>
    )
  }
  return <Div />
}

import React from 'react'
import { Row, Div, Text, Container, Col } from 'atomize-jnh'
import { useIntl } from 'gatsby-plugin-intl'
import WideIconCard from '../../Cards/WideIconCard'
import Svg1 from '../../../assets/balance-icon.svg'
import Svg2 from '../../../assets/chat-icon.svg'
import Svg4 from '../../../assets/security-icon.svg'
import Svg3 from '../../../assets/idea-icon.svg'

function renderGoodHands () {
  const intl = useIntl()
  const data = {
    langCode: intl.formatMessage({ id: 'langCode' }),
    title: intl.formatMessage({ id: 'inGoodHands.title' }),
    subtitle: intl.formatMessage({ id: 'inGoodHands.subtitle' })
  }

  const cardData = [
    {
      title: intl.formatMessage({ id: 'inGoodHands.descriptions.q1.title' }),
      subtitle: intl.formatMessage({ id: 'inGoodHands.descriptions.q1.subtitle' }),
      icon: Svg1
    },
    {
      title: intl.formatMessage({ id: 'inGoodHands.descriptions.q2.title' }),
      subtitle: intl.formatMessage({ id: 'inGoodHands.descriptions.q2.subtitle' }),
      icon: Svg2
    },
    {
      title: intl.formatMessage({ id: 'inGoodHands.descriptions.q3.title' }),
      subtitle: intl.formatMessage({ id: 'inGoodHands.descriptions.q3.subtitle' }),
      icon: Svg3
    },
    {
      title: intl.formatMessage({ id: 'inGoodHands.descriptions.q4.title' }),
      subtitle: intl.formatMessage({ id: 'inGoodHands.descriptions.q4.subtitle' }),
      icon: Svg4
    }
  ]

  return (
    <Div
      p={{
        t: { xs: '2rem', md: '4rem' },
        b: { xs: '2rem', md: '4rem' },
        l: { xs: '.2rem', md: '1.5rem', lg: '.2rem' },
        r: { xs: '.2rem', md: '1.trem', lg: '.2rem' }
      }}>
      <Container>
        <Row justify="center">
          <Col d={{ xs: 'none', md: 'flex' }} size={{ md: '6', lg: '6' }}>
            <Div p={{ t: '0.5rem' }}>
              <Text tag="h2" textTransform="uppercase" h="2.5rem" textColor="#37465a">
                {data.title}
              </Text>
              <Text tag="p" h="4.5rem" textColor="#37465a">
                {data.subtitle}
              </Text>
              <br />
              <br />
            </Div>
          </Col>
          <Col w="100%" d="flex" justify="center" size="6"></Col>
        </Row>

        <Row d="flex" justify="center">
          {cardData.map((value, index) => {
            return (
              <Col key={index} size={{ md: '6', lg: '3', xs: '12' }}>
                <Div m={{ b: { xs: '1rem' } }}>
                  <WideIconCard icon={value.icon} title={value.title} subtitle={value.subtitle} />
                </Div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Div>
  )
}

export default renderGoodHands

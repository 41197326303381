import React from 'react'
import PropTypes from 'prop-types'
import { Div, Button } from 'atomize-jnh'
import CoinCircle from '../../CoinCircle'

FieldButton.propTypes = {
  onChange: PropTypes.object,
  option: PropTypes.object,
  selectedCurrency: PropTypes.string
}

export default function FieldButton (props) {
  const { onChange, option, selectedCurrency } = props

  function borderButton (buttonCurrency) {
    if (selectedCurrency === buttonCurrency) {
      return 'rgb(35, 186, 255)'
    }
    return '#fff'
  }

  function buttonShadow (buttonCurrency) {
    if (selectedCurrency === buttonCurrency) {
      return '3'
    }
    return '2'
  }

  return (
    <Button
      onClick={onChange}
      prefix={
        option.value !== 'loading' && (
          <Div pos="absolute" left={{ xs: '32px', md: '12px' }} top="19px">
            <CoinCircle size={24} currencyCode={option.value !== 'loading' && option.value} />
          </Div>
        )
      }
      border="1px solid"
      borderColor={borderButton(option.value)}
      bg="#fff"
      rounded="md"
      textColor="#37465a"
      w={{ xs: '92px', md: '200px' }}
      h="60px"
      textWeight="800"
      m={{ t: { xs: '12px', md: '0' } }}
      shadow={buttonShadow(option.value)}
      hoverShadow="4">
      {option.value === 'loading' ? (
        <Div w="40px" m="0 auto"></Div>
      ) : (
        <Div d={{ xs: 'none', md: 'flex' }}>{option.label}</Div>
      )}
    </Button>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import Carousel from './'
// CREDITS for Carousel:
// https://medium.com/@incubation.ff/build-your-own-css-carousel-in-react-part-one-86f71f6670ca

function App (props) {
  const { children, clickFx } = props
  return (
    <Carousel clickFx={clickFx} title="Carousel">
      {children}
    </Carousel>
  )
}

App.propTypes = {
  children: PropTypes.any,
  clickFx: PropTypes.func
}

export default App

import React from 'react'
import PropTypes from 'prop-types'
import { Div, Container, Text, Col, Row } from 'atomize-jnh'

TableHeader.propTypes = {
  currency: PropTypes.string,
  global: PropTypes.string,
  change: PropTypes.string,
  price: PropTypes.string,
  operate: PropTypes.string,
  selectedCurrency: PropTypes.string,
  tableType: PropTypes.string
}

function TableHeader ({ currency, global, price, change, operate, selectedCurrency, tableType }) {
  function renderGlobalPrice () {
    if (selectedCurrency !== 'CLP' || tableType === 'landing') return false
    return (
      <Col d={{ xs: 'none', md: 'inline-block' }} size="2">
        <Text
          textWeight={textWeight}
          textAlign="center"
          tag="p"
          textColor="#c4ceda"
          textSize={{ xs: 'caption', md: 'caption' }}>
          {'🌎 '}
          {global}*
        </Text>
      </Col>
    )
  }

  const textWeight = '500'
  const responsiveColSize =
    selectedCurrency === 'CLP' ? { xs: '3', md: tableType === 'landing' ? '3' : '2' } : '3'

  return (
    <Div border={{ b: '1px solid' }} borderColor="#eceff1" p={{ b: '15px' }}>
      <Container>
        <Row>
          <Col size={{ xs: '6', md: '3' }}>
            <Text
              tag="p"
              textWeight={textWeight}
              textColor="#c4ceda"
              textSize={{ xs: 'caption', md: 'caption' }}>
              {currency}
            </Text>
          </Col>
          {renderGlobalPrice(selectedCurrency, tableType)}
          <Col size={{ xs: '3', md: '3' }}>
            <Text
              textAlign={{ md: 'center' }}
              textWeight={textWeight}
              tag="p"
              w={{ xs: '155%', md: '100%' }}
              textColor="#c4ceda"
              textSize={{ xs: 'caption', md: 'caption' }}>
              {price}
            </Text>
          </Col>
          <Col size={responsiveColSize}>
            <Text
              d={{ xs: 'none', md: 'block' }}
              textAlign="center"
              textWeight={textWeight}
              tag="p"
              textColor="#c4ceda"
              textSize={{ xs: 'caption', md: 'caption' }}>
              {change}
            </Text>
          </Col>
          <Col size={responsiveColSize}>
            <Text
              textWeight={textWeight}
              tag="p"
              textColor="#c4ceda"
              textAlign={{ md: 'center' }}
              textSize={{ xs: 'caption', md: 'caption' }}></Text>
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

export default TableHeader

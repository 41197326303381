import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Div } from 'atomize-jnh'
import getSecondaryCurrencies from '../../../helpers/getSecondaryCurrencies'
import getPairsBySecondaryCurrencies from '../../../helpers/getPairsBySecondaryCurrencies'
import rebuildCurrenciesObject from '../../../helpers/rebuildCurrenciesObject'
import validateGeckoPrice from '../../../helpers/validateGeckoPrice'
import CurrenciesTabSelector from './CurrenciesTabSelector'
import FetchDataSpinner from './FetchDataSpinner'
import TableHeader from './TableHeader'
import TableTitle from './TableTitle'
import './loader.css'
import { currenciesNames } from '../../../data/currencies'
import TableRow from './TableRow'
import GlobalPriceDisclaimer from './GlobalPriceDisclaimer'

const ResponsiveTable = props => {
  const { langCode, tableData, tableType, type } = props
  const [secondaryCurrencies, setSecondaryCurrencies] = useState([
    { value: 'loading', label: 'Loading pairs...' }
  ])
  const [searchResults, setSearchResults] = useState()
  const [selectedCurrency, setCurrency] = useState('CLP')
  const [formattedCurrencies, setFormattedCurrencies] = useState({ [`${selectedCurrency}`]: [] })
  const [loadedTicker, setLoadedTicker] = useState(false)
  const [hasLoadedGlobalPrice, setLoadedGlobalPrice] = useState(false)
  const [searchTerm, setSearchTerm] = useState({ target: { value: '' } })

  const handleChange = event => {
    const currencies = formattedCurrencies[selectedCurrency]
    setSearchTerm({ target: { value: event.target.value } })
    const results = currencies.filter(currency => {
      return (
        currency.amountCurrencyCode.toLowerCase().includes(event.target.value.toLowerCase()) ||
        currency.currencyName.toLowerCase().includes(event.target.value.toLowerCase())
      )
    })

    const newObj = {
      [selectedCurrency]: results
    }

    setSearchResults({ ...formattedCurrencies, ...newObj })
  }

  const handleSetCurrency = value => {
    setCurrency(value)
    setSearchTerm({ target: { value: '' } })
  }

  useEffect(() => {
    fetch('https://stats.orionx.com/ticker')
      .then(response => response.json())
      .then(resultData => {
        const ticker = getPairsBySecondaryCurrencies(resultData)
        if (tableType === 'landing') {
          setFormattedCurrencies({ CLP: ticker.CLP.slice(0, 4) })
          setSearchResults({ CLP: ticker.CLP.slice(0, 4) })
          setLoadedTicker(true)
        } else {
          setFormattedCurrencies(ticker)

          if (selectedCurrency === 'CLP') {
            fetch(
              'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Cripple%2Cstellar%2Ctron%2Cbinancecoin%2Cdai%2Clitecoin%2Cbitcoin-cash%2Cdash%2Ctether&vs_currencies=clp'
            )
              .then(response => response.json())
              .then(resultData2 => {
                const newFormattedCurrencies = []
                for (const pairName in ticker.CLP) {
                  const pairData = ticker.CLP[pairName]
                  newFormattedCurrencies.push({
                    ...pairData,
                    globalPrice: validateGeckoPrice(
                      resultData2[currenciesNames[pairData.amountCurrencyCode].geckoName]
                    )
                  })
                }
                const rebuildObject = rebuildCurrenciesObject(ticker, newFormattedCurrencies, 'CLP')
                setLoadedGlobalPrice(true)
                setFormattedCurrencies(rebuildObject)
                setSearchResults(rebuildObject)
                setSecondaryCurrencies(getSecondaryCurrencies(resultData, 'tab-selector'))
                setLoadedTicker(true)
              })
          }
        }
      })
  }, [type])

  return (
    <>
      {tableType === 'full-table' && (
        <CurrenciesTabSelector
          options={secondaryCurrencies}
          selectedCurrency={selectedCurrency}
          setCurrency={handleSetCurrency}
        />
      )}
      <Div align="center" shadow="3" rounded="md" bg="white">
        <TableTitle
          searchValue={searchTerm.target.value}
          tableType={tableType}
          onChange={handleChange}
        />
        <TableHeader
          currency={tableData.header.currency}
          global={tableData.header.global}
          change={tableData.header.change}
          operate={tableData.header.operate}
          selectedCurrency={selectedCurrency}
          price={tableData.header.price}
          tableType={tableType}
        />
        {loadedTicker ? (
          searchResults[selectedCurrency].map((data, index) => {
            let customRoute
            if (index < 3) {
              if (data.amountCurrencyCode === 'BTC') {
                customRoute = '/bitcoin-chile'
              } else if (data.amountCurrencyCode === 'ETH') {
                customRoute = '/ethereum-chile'
              }
            }

            return (
              <TableRow
                key={index}
                currencyCode={data.amountCurrencyCode}
                currencyName={data.currencyName}
                customRoute={customRoute }
                lastPrice={data.lastPrice}
                variant={data.variation24h}
                code={data.code}
                globalPrice={data.globalPrice}
                clpPrice={data.clpPrice}
                actionButton={tableData.actionButton}
                selectedCurrency={selectedCurrency}
                tableType={tableType}
                langCode={langCode}
              />
            )
          })
        ) : (
          <FetchDataSpinner />
        )}
        <GlobalPriceDisclaimer globalPrice={hasLoadedGlobalPrice} />
      </Div>
    </>
  )
}

export default ResponsiveTable

ResponsiveTable.propTypes = {
  tableData: PropTypes.object,
  langCode: PropTypes.string,
  tableType: PropTypes.string,
  type: PropTypes.string
}

import styled from 'styled-components'

export const NEXT = 'NEXT'
export const PREV = 'PREV'

export const CarouselContainer = styled.div`
  display: flex;
  transition: ${props => (props.sliding ? 'none' : 'transform 0.5s ease')};
  transform: ${props => {
    if (!props.sliding) return 'translateX(calc(-80% - 20px))'
    if (props.dir === PREV) return 'translateX(calc(2 * (-80% - 20px)))'
    return 'translateX(0%)'
  }};
`

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

export const CarouselSlot = styled.div`
  flex: 1 0 100%;
  flex-basis: 78%;
  margin-left: 1.6rem;
  order: ${props => props.order};
`

export const CarouselSlot2 = styled.div`
  flex: 1 0 100%;
  flex-basis: 89%;
  order: ${props => props.order};
`

export const SlideButton = styled.button`
  color: #ffffff;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 100;
  padding: 10px;
  background-color: #f66f3e;
  border: 1px solid white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
  float: ${props => props.float};

  &:active {
    position: relative;
    top: 1px;
  }
  &:focus {
    outline: 0;
  }
`

export const AppContainer = styled.div`
  font-family: sans-serif;
  text-align: center;
  width: 75%;
`

export const ExtraInfo = styled.div`
  margin-top: 25px;
  display: inline-block;
`

export const Code = styled.code`
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  margin: 0;
  padding: 0.2em 0.4em;
`

import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Div, Text } from 'atomize'
import logo from '../../../assets/logo-white.svg'
import CarouselExample from '../../../containers/Carousel/media'
import defaultBg2 from '../../../assets/Analisis.png'
import { FaMediumM } from 'react-icons/fa'
import { useIntl } from 'gatsby-plugin-intl'
import { cardData } from './data/social-publications'

const Card = ({ data }) => {
  Card.propTypes = {
    data: PropTypes.object
  }

  return (
    <Div
      bgImg={defaultBg2}
      bgSize="cover"
      w={{ xs: '16.4rem', md: '14rem', lg: '19rem', xl: '21rem' }}
      bg="#4d68f0"
      h={{ xs: '16.4rem', md: '14rem', lg: '19rem', xl: '21rem' }}
      pos="relative"
      shadow="3"
      cursor="pointer"
      rounded="md"
      hoverShadow="5"
      onClick={() => window.open(data.link)}>
      <Row>
        <Col size="10"></Col>
        <Col size="2">
          <Div m={{ t: '10px', l: { xs: '-15px', md: '-10px', lg: '0' } }}>
            <FaMediumM size="30px" color="white" />
          </Div>
        </Col>
      </Row>
      <Div pos="absolute" bottom={{ md: '60px', lg: '80px' }} m={{ l: '12px' }}>
        <Text style={{ userSelect: 'none' }} tag="h4" textSize="subheader" textColor="white">
          {data.title}
        </Text>
        <Text style={{ userSelect: 'none' }} textColor="white">
          {data.body}
        </Text>
      </Div>
      <Div
        bgImg={logo}
        bgRepeat="no-repeat"
        cursor="pointer"
        pos="absolute"
        bottom="10px"
        right="10px"
        m={{ r: '5px', t: { xl: '-7px' } }}
        w={{ xs: '9.5rem', md: '10rem' }}
        h={{ xs: '60px', md: '2.7rem' }}
        bgSize={{ xs: 'contain', md: 'contain' }}></Div>
    </Div>
  )
}

const SocialPublications = () => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'mediaPublications.title' })
  return (
    <Container>
      <Div m={{ b: '2rem', t: '2rem' }}>
        <Text textColor="#4d68f0" textWeight="700" textSize="title">
          {title}
        </Text>
      </Div>

      <Row d={{ xs: 'none', md: 'flex' }} p={{ b: '3rem' }} align="center" jusitfy="center">
        {cardData.map((data, index) => {
          return (
            <Col key="index" size={{ md: '4' }}>
              <Card data={data} />
            </Col>
          )
        })}
      </Row>
      <Div d={{ md: 'none' }}>
        <CarouselExample>
          {cardData.map((data, index) => {
            return <Card key={index} data={data} />
          })}
        </CarouselExample>
      </Div>
    </Container>
  )
}

export default SocialPublications

import './index.scss'
import PropTypes from 'prop-types'
import React from 'react'
import SEO from '../components/Seo'
import Layout from '../layouts/'
import { injectIntl } from 'gatsby-plugin-intl'
import Hero from '../components/Pages/Landing/Hero'
import Table from '../components/Pages/Landing/Table'
import DynamicQuadCards from '../components/Pages/Landing/DynamicQuadCards'
import InGoodHands from '../components/Pages/Landing/InGoodHands'
import ExchangeStats from '../components/Pages/Landing/ExchangeStats'
import StartInvest from '../components/Pages/Landing/StartInvest'
import InTheMedia from '../components/Pages/Landing/InTheMedia'
import SocialPublications from '../components/Pages/Landing/SocialPublications'

const IndexPage = ({ intl, location }) => {
  return (
    <Layout>
      <SEO
        location={location}
        defaultImage="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=1952&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D"
        title={intl.formatMessage({ id: 'title' })}
      />
      <>
        <Hero />
        <Table />
        <DynamicQuadCards />
        <InGoodHands />
        <ExchangeStats />
        <InTheMedia />
        <StartInvest />
        <SocialPublications />
      </>
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object,
  intl: PropTypes.object
}

export default injectIntl(IndexPage)

const cardData = [
  {
    title: 'Ciclos de mercado en criptomonedas',
    body:
      'La palabra ciclo, proveniente del griego kýklos, etimológicamente significa circulo, rueda. De ahí el nombre...',
    link: 'https://medium.com/orionx/ciclos-de-mercado-en-criptomonedas-30e9099f26c2'
  },
  {
    title: 'Psicología de masas en los mercados',
    body:
      'El precio comienza a subir. “No hay razones para esta subida”, piensas. Revisaste las noticias ...',
    link: 'https://medium.com/orionx/psicolog%C3%ADa-de-masas-en-los-mercados-6ff73b128e79'
  },
  {
    title: '¿Qué son las stablecoins?',
    body:
      'Las variaciones bruscas en el precio de un activo o moneda son algo difícil de tolerar para el ...',
    link: 'https://medium.com/orionx/qu%C3%A9-son-las-stablecoins-a869bebac0f0'
  }
]

export { cardData }

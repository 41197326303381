import React from 'react'
import PropTypes from 'prop-types'
import { Div } from 'atomize-jnh'
import FieldButton from './FieldButton'

class OrderTypeSegmentedControl extends React.Component {
  renderOptions () {
    return this.props.options.map((option, index) => {
      const select = type => event => this.props.onChange(type)

      return (
        <Div key={index} p={{ r: '24px' }}>
          <FieldButton
            onChange={select(option.value)}
            option={option}
            selectedCurrency={this.props.selectedCurrency}
          />
        </Div>
      )
    })
  }

  render () {
    return <div style={{ display: 'flex' }}>{this.renderOptions()}</div>
  }
}
OrderTypeSegmentedControl.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selectedCurrency: PropTypes.string
}

export default OrderTypeSegmentedControl

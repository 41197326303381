import React from 'react'
import PropTypes from 'prop-types'
import { Div, Text } from 'atomize-jnh'

function SimpleCard (props) {
  const { title, variant } = props

  function simpleCardStylesInterface () {
    switch (variant) {
      case 'primary':
        return {
          bg: '#1478f6'
        }
      case 'secondary':
        return {
          bg: '#47baf8'
        }
      default:
        return {
          bg: '#1478f6'
        }
    }
  }

  return (
    <Div
      cursor="pointer"
      align="center"
      shadow="3"
      hoverShadow="5"
      rounded="lg"
      p="1rem"
      m="0 auto"
      h={{ xs: '19.5rem', md: '12rem', lg: '19rem' }}
      {...simpleCardStylesInterface()}>
      <Text tag="h3" textSize="title" textAlign="center" textColor="#fff">
        {title}
      </Text>
    </Div>
  )
}

SimpleCard.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string
}

export default SimpleCard

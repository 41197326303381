import React from 'react'
import { Row, Div, Container, Col } from 'atomize-jnh'
import EmolIMG from '../../../assets/Emol.png'
import LunIMG from '../../../assets/LUN.png'
import LaTerceraIMG from '../../../assets/LaTercera.png'
import DfIMG from '../../../assets/DiarioFinanciero.png'
import PulsoIMG from '../../../assets/Pulso.png'
import CarouselExample from '../../../containers/Carousel/media'

function renderInTheMedia () {
  return (
    <Container p={{ t: '3rem', b: '3rem' }}>
      <Row d={{ xs: 'none', md: 'flex' }} justify="center">
        <Col order={{ xs: '1', md: '1' }} size={{ xs: '9', md: '2' }}>
          <Div
            m={{ t: { xs: '12px', md: '0' } }}
            bgImg={LaTerceraIMG}
            w="100%"
            h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
            bgSize="contain"
            bgRepeat="no-repeat"
            bgPos="center"
          />
        </Col>
        <Col order={{ xs: '5', md: '2' }} size={{ xs: '4', md: '2' }}>
          <Div
            m={{ t: { xs: '12px', md: '0' } }}
            w="100%"
            bgPos="center"
            bgImg={PulsoIMG}
            h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
            bgSize="contain"
            bgRepeat="no-repeat"
          />
        </Col>
        <Col order={{ xs: '3', md: '3' }} size={{ xs: '9', md: '3' }}>
          <Div
            m={{ t: { xs: '12px', md: '0' } }}
            w="100%"
            bgImg={LunIMG}
            h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
            bgSize="contain"
            bgRepeat="no-repeat"
            bgPos="center"
          />
        </Col>
        <Col order={{ xs: '4' }} size={{ xs: '4', md: '2' }}>
          <Div
            m={{ t: { xs: '12px', md: '0' } }}
            w="100%"
            bgImg={EmolIMG}
            h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
            bgSize="contain"
            bgRepeat="no-repeat"
            bgPos="center"
          />
        </Col>
        <Col order={{ xs: '2', md: '5' }} size={{ xs: '9', md: '2' }}>
          <Div
            m={{ t: { xs: '21px', md: '0' } }}
            w="100%"
            bgImg={DfIMG}
            h={{ xs: '2.7rem', md: '2rem', lg: '2rem' }}
            bgSize="contain"
            bgRepeat="no-repeat"
            bgPos="center"
          />
        </Col>
      </Row>
      <Div d={{ md: 'none' }}>
        <CarouselExample type="mediaNews">
          <Col d="flex" size="12">
            <Div
              m={{ t: { xs: '12px', md: '0' } }}
              bgImg={LaTerceraIMG}
              w="100%"
              h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
              bgSize="contain"
              bgRepeat="no-repeat"
              bgPos="center"
            />
          </Col>
          <Col d="flex" size="12">
            <Div
              m={{ t: { xs: '12px', md: '0' } }}
              w="100%"
              bgPos="center"
              bgImg={PulsoIMG}
              h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
              bgSize="contain"
              bgRepeat="no-repeat"
            />
          </Col>
          <Col d="flex" size="12">
            <Div
              m={{ t: { xs: '12px', md: '0' } }}
              w="100%"
              bgImg={LunIMG}
              h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
              bgSize="contain"
              bgRepeat="no-repeat"
              bgPos="center"
            />
          </Col>
          <Col d="flex" size="12">
            <Div
              m={{ t: { xs: '12px', md: '0' } }}
              w="100%"
              bgImg={EmolIMG}
              h={{ xs: '2.3rem', md: '2rem', lg: '2rem' }}
              bgSize="contain"
              bgRepeat="no-repeat"
              bgPos="center"
            />
          </Col>
          <Col d="flex" size="12">
            <Div
              m={{ t: { xs: '21px', md: '0' } }}
              w="100%"
              bgImg={DfIMG}
              h={{ xs: '2.7rem', md: '2rem', lg: '2rem' }}
              bgSize="contain"
              bgRepeat="no-repeat"
              bgPos="center"
            />
          </Col>
        </CarouselExample>
      </Div>
    </Container>
  )
}

export default renderInTheMedia

import React from 'react'
import { Row, Col, Text, Container, Div } from 'atomize-jnh'
import image1 from '../../../assets/Capital-Tranzado.svg'
import image2 from '../../../assets/Usuarios-Registrados.svg'
import image3 from '../../../assets/Mercado.svg'
import { useIntl } from 'gatsby-plugin-intl'

const ExchangeStats = () => {
  const intl = useIntl()
  const defaultCardStyles = {
    align: 'center',
    p: '1rem',
    bg: 'white',
    h: { xs: '13rem', md: '15rem' },
    w: { xs: '13rem', md: '15rem' },
    m: { b: '1rem', r: 'auto', l: 'auto', t: { xs: '24px', md: '0' } }
  }
  const data = {
    totalVolume: intl.formatMessage({ id: 'exchangeStats.totalVolume' }),
    volumeText: intl.formatMessage({ id: 'exchangeStats.volumeText' }),
    totalUsers: intl.formatMessage({ id: 'exchangeStats.totalUsers' }),
    usersText: intl.formatMessage({ id: 'exchangeStats.usersText' }),
    totalMarkets: intl.formatMessage({ id: 'exchangeStats.totalMarkets' }),
    marketText: intl.formatMessage({ id: 'exchangeStats.marketText' })
  }

  return (
    <Div
      p={{
        t: { xs: '2.5rem', md: '4rem' },
        b: { xs: '5rem', md: '4rem' },
        l: { xs: '.5rem' },
        r: { xs: '.5rem' }
      }}
      bg="linear-gradient(30deg, #42bcfb, #1f87f6)">
      <Container>
        <Row d="flex" justify="center">
          <Col size={{ md: '4', xs: '12' }}>
            <Div {...defaultCardStyles} bg="rgba(0,0,0,0)" pos="relative">
              <Div
                align="center"
                w="100%"
                h="15rem"
                bgImg={image1}
                m={{ t: '18px', l: '-10px' }}
                bgSize="contain"
                bgRepeat="no-repeat"
              />
            </Div>
            <Div m={{ t: '24px' }}>
              <Text
                textAlign="center"
                style={{ userSelect: 'none' }}
                tag="h3"
                textSize="heading"
                textColor="#fff">
                {data.totalVolume}
              </Text>
              <Text
                textAlign="center"
                style={{ userSelect: 'none' }}
                tag="h3"
                textSize="heading"
                textColor="#fff">
                {data.volumeText}
              </Text>
            </Div>
          </Col>
          <Col size={{ md: '4', xs: '12' }}>
            <Div {...defaultCardStyles} pos="relative" bg="rgba(0,0,0,0)">
              <Div
                align="center"
                w="100%"
                h="15rem"
                bgImg={image2}
                bgSize="contain"
                bgRepeat="no-repeat"
              />
            </Div>
            <Div m={{ t: '24px' }}>
              <Text
                textAlign="center"
                style={{ userSelect: 'none' }}
                tag="h3"
                textSize="heading"
                textColor="#fff">
                {data.totalUsers}
              </Text>
              <Text
                textAlign="center"
                style={{ userSelect: 'none' }}
                tag="h3"
                textSize="heading"
                textColor="#fff">
                {data.usersText}
              </Text>
            </Div>
          </Col>
          <Col size={{ md: '4', xs: '12' }}>
            <Div {...defaultCardStyles} pos="relative" bg="rgba(0,0,0,0)">
              <Div
                align="center"
                w="100%"
                h="15rem"
                m={{ t: '32px', l: '12px' }}
                bgImg={image3}
                bgSize="contain"
                bgRepeat="no-repeat"
              />
            </Div>
            <Div m={{ t: { xs: '12px', md: '24px' } }}>
              <Text
                style={{ userSelect: 'none' }}
                tag="h3"
                textAlign="center"
                textSize="heading"
                textColor="#fff">
                {data.totalMarkets}
              </Text>
              <Text
                textAlign="center"
                style={{ userSelect: 'none' }}
                tag="h3"
                textSize="heading"
                textColor="#fff">
                {data.marketText}
              </Text>
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

export default ExchangeStats

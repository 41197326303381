import React from 'react'
import { Container, Col, Text, Row, Input, Div } from 'atomize-jnh'
import Button from '../../Button'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

function renderStartInvest (props) {
  let form = ''
  const intl = useIntl()
  const data = {
    title: intl.formatMessage({ id: 'callToAction.title' }),
    subtitle: intl.formatMessage({ id: 'callToAction.subtitle' }),
    emailLabel: intl.formatMessage({ id: 'callToAction.emailLabel' }),
    buttonContent: intl.formatMessage({ id: 'callToAction.buttonContent' })
  }

  return (
    <div style={{ width: '100vw', background: '#e9f3fe' }}>
      <Container p={{ t: '3rem', b: '3rem' }}>
        <Row d="flex" justify="center">
          <Col size={{ xs: '12', lg: '6' }}>
            <Text
              tag="h2"
              p={{ b: { xs: '0.5rem' }, l: { xs: '0.1rem' }, r: { xs: '0.1rem' } }}
              textAlign={{ xs: 'center', lg: 'left' }}
              textSize="title"
              textColor="#1579f6">
              {data.title}
            </Text>
            <Text
              tag="p"
              p={{ b: { xs: '0.5rem' } }}
              textSize="body"
              textAlign={{ xs: 'center', lg: 'left' }}
              textColor="#1579f6">
              {data.subtitle}
            </Text>
          </Col>
          <Col size={{ xs: '12', md: '12', lg: '6' }}>
            <Container p={{ t: { md: '0.8rem' } }} d="flex">
              <Container m={{ b: { xs: '0.8rem' } }}>
                <Div w={{ md: '80%', lg: '100%' }} m="0 auto">
                  <label htmlFor="email" />
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    onChange={event => {
                      form = event.target.value
                    }}
                    rounded="circle"
                    placeholder={data.emailLabel}
                  />
                </Div>
              </Container>
              <Div d={{ xs: 'none', lg: 'flex' }}>
                <Button
                  onClick={() => navigate(`/funnel?id=register&email=${form}`)}
                  variant="primary"
                  content={data.buttonContent}
                />
              </Div>
            </Container>
          </Col>
          <Div d={{ xs: 'flex', lg: 'none' }}>
            <Button
              onClick={() => navigate(`/funnel?id=register&email=${form}`)}
              d={{ xs: 'none', md: 'flex' }}
              variant="primary"
              content={data.buttonContent}
            />
          </Div>
        </Row>
      </Container>
    </div>
  )
}

export default renderStartInvest

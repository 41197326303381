import React from 'react'
import { Row, Col, Div, Container } from 'atomize-jnh'
import { useIntl } from 'gatsby-plugin-intl'
import EngageMessage from './EngageMessage'
import EmailForm from './EmailForm'
import Illustration from './Illustration'

const Hero = () => {
  const intl = useIntl()

  const heroData = {
    langCode: intl.formatMessage({ id: 'langCode' }),
    title: intl.formatMessage({ id: 'hero.heroTitle' }),
    mailLabel: intl.formatMessage({ id: 'hero.heroMailInput' }),
    button: intl.formatMessage({ id: 'hero.heroButtonContent' })
  }

  return (
    <Div
      bg="linear-gradient(30deg, rgba(12,110,246,1) 30%, rgba(76,193,249,1) 100%, rgba(76,193,249,1) 100%)"
      p={{
        t: { xs: '3rem', md: '3rem', lg: '4rem' },
        b: { xs: 0, md: '2rem' },
        l: { lg: '1rem' },
        r: { lg: '1rem' }
      }}>
      <Container>
        <Row>
          <Col size={{ xs: '12', md: '7', lg: '7' }}>
            <Div>
              <EngageMessage
                title={heroData.title}
                punchline={intl.formatMessage({ id: 'hero.punchline' })}
                punchline2={intl.formatMessage({ id: 'hero.punchline2' })}
              />
              <EmailForm inputLabel={heroData.mailLabel} buttonText={heroData.button} />
            </Div>
          </Col>
          <Col d="flex" justify="center" size={{ xs: '12', md: '5' }}>
            <Illustration />
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

export default Hero
